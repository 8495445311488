@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
    font-family: 'Poppins', sans-serif;
    background: #f9fbfd;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
}

/* SIDEBAR */
#sidebar {
    background: #fff;
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
    color: #818181;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    color: #818181;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7c8798;
    background: #fafafa;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
    color: #818181;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #7c8798;
}

.list-divider {
    background: #818181;
    height: 1px;
    display: block;
    /* margin: 10px 0 20px 30px; */
    opacity: 0.1;
}
/* END SIDEBAR */

/* TOPBAR */
#lgselect {
    border-radius: 60px;
    padding: 13px 43px 13px 28px;
    display: inline-block;
    line-height: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-appearance: none;
}

#divider_topbar {
    margin-top: 10px;
}
/* END TOPBAR */

/* MAIN */
.main {
    background: #f9fbfd;
    width: calc(100% - 250px);
    /* padding: 40px; */
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
/* END MAIN */

/* BREADCRUMB */
.page-breadcrumb {
    padding: 30px 35px 0;
}
/* END BREADCRUMB */

/* CARD */
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #e9ecef;
    border-radius: 0.25rem;
    margin-bottom: 30px;
}

.card-title {
    position: relative;
    margin-bottom: 10px;
}
/* END CARD */

/* CONTENT */
.content {
    background: #f9fbfd;
    position: relative;
    display: block;
}

.content .container-fluid {
    padding: 35px;
}
/* END CONTENT */

/* TABLE */
.table-responsive {
    height: 30em;
    overflow-y: hidden;
}

table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}

table thead {
    /* head takes the height it requires, 
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
}

table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
}

table tbody tr {
    width: 100%;
}

table thead,
table tbody tr {
    display: table;
    table-layout: fixed;
}

/* END TABLE */
/* CUSTOMIZE SEARCH BAR */
.customize-input {
    position: relative;
    width: 50%;
    margin: auto;
}
.customize-input .form-control {
    padding: 13px 43px 13px 28px;
    display: inline-block;
    line-height: 13px;
}
.custom-radius {
    border-radius: 60px;
}
.customize-input .form-control-icon {
    position: absolute;
    top: 10px;
    right: 14px;
    height: 20px;
    width: 20px;
}
/* END CUSTOMIZE SEARCH BAR */

/* BUTTON */
.btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.btn-rounded {
    border-radius: 2em;
}

/* END BUTTON */

/* HOVER */

/* Tooltip text */
.tooltipBtn .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  width: 120px;
  bottom: 100%;
  left: 67%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipBtn:hover .tooltiptext {
  visibility: visible;
}
/* END HOVER */

